* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
}

body.no-scroll {
  overflow: hidden;
}
